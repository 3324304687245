import { useTheme } from 'styled-components';

import { Box, Chart } from '@core/component';
import { useContextCurrency, useContextMediaQuery } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, ThemeProps } from '@core/type';
import { InvestmentChartsResponse } from '@core/type/api';
import { getPaletteHandlers } from '@core/util';

import { ChartDescription } from './content/ChartDescription';
import { ContentBlock } from './content/ContentBlock';
import { FormBlock } from './content/FormBlock';
import { InvestmentLinkBlock } from './content/InvestmentLinkBlock';
import { WidgetChartInvestmentProps } from './interface-charts';

const COMPENSATION_TOOLTIP_TOP = 35;

export const WidgetChartInvestment = ({
  ...props
}: WidgetChartInvestmentProps & InvestmentChartsResponse) => {
  const theme = useTheme() as ThemeProps;
  const { palette } = theme;
  const { getColor } = getPaletteHandlers(palette);

  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { isMobile } = useContextMediaQuery();

  const pastPriceData = props.pastTotals?.map(({ timestamp, portfolioTotal, growth }) => ({
    x: timestamp,
    y: portfolioTotal,
    tooltipData: {
      growth,
    },
  }));

  const forecastPriceData = props.forecastTotals?.map(({ timestamp, portfolioTotal, growth }) => ({
    x: timestamp,
    y: portfolioTotal,
    tooltipData: {
      growth,
    },
  }));

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      gap={'24px'}
      margin={'0 auto'}
      maxWidth={'1091px'}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      <Box width={'100%'} maxWidth={'435px'}>
        <ContentBlock metalIso={props.metalIso} initialInvestment={props.initialInvestment} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} width={isMobile ? '100%' : '51%'} gap={'24px'}>
        <FormBlock {...props} />
        <Box>
          {pastPriceData && forecastPriceData ? (
            <Chart
              metalIso={props.metalIso}
              dataSeries={[...pastPriceData, ...forecastPriceData]}
              language={language}
              currency={currency}
              timeUnit={'year'}
              hasLogo={false}
              tooltipType={'investment'}
              compensationTooltipTop={COMPENSATION_TOOLTIP_TOP}
              annotations={{
                lineToday: {
                  type: 'line',
                  xMin: forecastPriceData[0].x,
                  xMax: forecastPriceData[0].x,
                  borderColor: getColor({ commonType: EColor.BLUE, intensity: EColor.R400 }),
                  borderWidth: 1,
                },
              }}
              datasets={[
                {
                  data: [...pastPriceData, ...forecastPriceData],
                },
                // TODO [GARB-4711]: add multiple lines
                // {
                //   data: pastPriceData,
                // },
                // {
                //   data: forecastPriceData,
                //   fill: false,
                //   // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                //   borderDash: [5, 2],
                // },
              ]}
            />
          ) : (
            <Box height={'450px'} />
          )}
          <ChartDescription />
          {isMobile && (
            <InvestmentLinkBlock
              metalIso={props.metalIso}
              initialInvestment={props.initialInvestment}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
