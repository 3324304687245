import { useState } from 'react';

import {
  FORECAST_YEAR_COUNT,
  INITIAL_INVESTMENT_DEFAULT,
  MONTHLY_INVESTMENT_DEFAULT,
  PAST_DATA_YEAR_COUNT,
} from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useQueryApi } from '@core/hook';
import { EMetal, Price } from '@core/type';
import { EQueryKey, InvestmentChartsRequest, InvestmentChartsResponse } from '@core/type/api';
import { WidgetChartInvestment } from '@core/widget';

export const ViewInvestmentCharts = () => {
  const { currency } = useContextCurrency();

  const [hasMonthlyInvestment, setHasMonthlyInvestment] = useState<boolean>(false);
  const [metalValue, setMetalValue] = useState<string>(EMetal.GOLD);
  const [monthlyInvestmentValue, setMonthlyInvestmentValue] = useState<Price>(
    MONTHLY_INVESTMENT_DEFAULT,
  );
  const [initialInvestmentValue, setInitialInvestmentValue] = useState<Price>(
    INITIAL_INVESTMENT_DEFAULT,
  );

  const { data } = useQueryApi<InvestmentChartsResponse, InvestmentChartsRequest>(
    EQueryKey.INVESTMENT_CHARTS,
    {
      metalIso: metalValue as EMetal,
      currencyIso: currency,
      initialInvestment: initialInvestmentValue,
      monthlyInvestment: hasMonthlyInvestment ? monthlyInvestmentValue : 0,
      pastDataYearCount: PAST_DATA_YEAR_COUNT,
      forecastYearCount: FORECAST_YEAR_COUNT,
    },
    {
      enabled: !!metalValue && !!initialInvestmentValue,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  return (
    <>
      <WidgetChartInvestment
        {...{
          metalValue,
          setMetalValue,
          hasMonthlyInvestment,
          setHasMonthlyInvestment,
          setInitialInvestmentValue,
          setMonthlyInvestmentValue,
          ...data,
        }}
      />
    </>
  );
};
