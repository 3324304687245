import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import withTranslation from 'next-translate/withTranslation';

import { Box, Button, Modal, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EActionAttribute, ESize, ETypographyVariant, WithTranslation } from '@core/type';
import { formatCurrency } from '@core/util';

import { PriceModalProps } from '../interface-charts';
import { ModalPriceChange } from './ModalPriceChange';

const ModalPriceBase = ({
  isOpen,
  onCloseModal,
  categoryTitleDowncase,
  secondPrefix,
  i18n: { t },
  deskask,
  deskbid,
  priceChange,
  metalPrice,
}: PriceModalProps & WithTranslation): JSX.Element => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();

  const { t: tProduct } = useTranslation(Translate.common.PRODUCT);

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal} size={ESize.LG}>
      <Modal.Header>
        <Typography variant={ETypographyVariant.H4} textAlign="center">
          {t('chart.show.priceDetailsModal.header', { categoryTitleDowncase })}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Box marginBottom="8px">
          <Typography width="130px" display="inline-block">
            {t('chart.show.priceDetailsModal.spotPrice')}
          </Typography>
          <Typography display="inline-block" variant={ETypographyVariant.H6}>
            {formatCurrency(language, currency, metalPrice)}
          </Typography>
        </Box>
        <Box marginBottom="8px">
          <Typography width="130px" display="inline-block">
            {t('chart.show.priceDetailsModal.buy')}
          </Typography>
          <Typography display="inline-block" variant={ETypographyVariant.H6}>
            {formatCurrency(language, currency, deskbid)}
          </Typography>
        </Box>
        <Box marginBottom="8px">
          <Typography width="130px" display="inline-block">
            {t('chart.show.priceDetailsModal.sell')}
          </Typography>
          <Typography display="inline-block" variant={ETypographyVariant.H6}>
            {formatCurrency(language, currency, deskask)}
          </Typography>
        </Box>
        <Box marginBottom="32px">
          <Typography width="130px" display="inline-block">
            {t('chart.show.priceDetailsModal.spread')}
          </Typography>
          <Typography display="inline-block" variant={ETypographyVariant.H6}>
            <ModalPriceChange priceChange={priceChange} isPositive={priceChange > 0} />
          </Typography>
        </Box>
        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.spotPrice')}: `}
          </Typography>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.spotPriceHtml`}
            values={{
              categoryTitleDowncase,
              secondPrefix,
            }}
            components={{
              boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
            }}
          />
        </Box>

        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.buyPrice')}: `}
          </Typography>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.buyPriceHtml`}
            values={{
              categoryTitleDowncase,
            }}
            components={{
              boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
            }}
          />
        </Box>

        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.sellPrice')}: `}
          </Typography>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.sellPriceHtml`}
            values={{
              categoryTitleDowncase,
            }}
            components={{
              boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6} />,
            }}
          />
        </Box>

        <Box marginBottom="16px">
          <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H6}>
            {`${t('chart.show.priceDetailsModal.spread')}: `}
          </Typography>
          <Trans i18nKey={`${Translate.common.WIDGET}:chart.show.priceDetailsModal.spreadText`} />
        </Box>
      </Modal.Body>

      <Modal.Footer>
        <Box display="flex" justifyContent="center">
          <Button onClick={onCloseModal} padding="0 16px">
            {tProduct('btnOkThanks')}
          </Button>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const ModalPrice = withTranslation(ModalPriceBase, Translate.common.WIDGET);
