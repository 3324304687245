import { Icon, Typography } from '@core/component';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EColor, EIcon, ESize, ETypographyVariant } from '@core/type';
import { formatCurrency } from '@core/util';

import { PriceModalProps } from './interface-charts-price-modal';

export const ModalPriceChange = ({ priceChange, isPositive }: PriceModalProps) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const color = isPositive ? EColor.SUCCESS : EColor.DANGER;

  return (
    <>
      {priceChange !== 0 && (
        <>
          <Icon
            display="inline-block"
            type={EIcon.TRIANGLE}
            transform={isPositive ? 'rotate(180deg)' : undefined}
            color={color}
            size={ESize.XXXS}
            marginRight="5px"
          />
          <Typography
            display="inline-block"
            color={color}
            variant={ETypographyVariant.H6}
            lineHeight="14px"
            marginRight="8px"
          >
            {formatCurrency(language, currency, priceChange, { signDisplay: 'never' })}
          </Typography>
        </>
      )}
    </>
  );
};
