import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';

import {
  Box,
  ButtonRadioGroup,
  ButtonRadioSmallBordered,
  Icon,
  InputCheckbox,
  InputRangePrice,
  Typography,
} from '@core/component';
import {
  INITIAL_INVESTMENT_DEFAULT,
  INITIAL_INVESTMENT_MAX,
  INITIAL_INVESTMENT_MIN,
  INITIAL_INVESTMENT_STEP,
  MONTHLY_INVESTMENT_DEFAULT,
  MONTHLY_INVESTMENT_MAX,
  MONTHLY_INVESTMENT_MIN,
  MONTHLY_INVESTMENT_STEP,
  Translate,
} from '@core/constant';
import { useContextCurrency } from '@core/context';
import { useLanguage } from '@core/hook';
import { EActionAttribute, EColor, EIcon, EMetal, ESize, ETypographyVariant } from '@core/type';
import { InvestmentChartsResponse } from '@core/type/api';
import { formatCurrency } from '@core/util';

import { ModalInvested5YearsAgo } from '../ModalPrice';
import { WidgetChartInvestmentProps } from '../interface-charts';

export const FormBlock = ({
  metalValue,
  setMetalValue,
  hasMonthlyInvestment,
  setHasMonthlyInvestment,
  setInitialInvestmentValue,
  setMonthlyInvestmentValue,
  ...props
}: WidgetChartInvestmentProps & InvestmentChartsResponse) => {
  const { language } = useLanguage();
  const { currency } = useContextCurrency();
  const { t: tWidget } = useTranslation(Translate.common.WIDGET);
  const { t: tHeader } = useTranslation(Translate.layout.HEADER);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isPositiveProfit = props.growth > 0;

  const onChangeMonthlyInvestment = (value: number) => {
    setMonthlyInvestmentValue(value);
  };

  const onChangeStartingInvestment = (value: number) => {
    setInitialInvestmentValue(value);
  };

  return (
    <>
      <Box>
        <Typography variant={ETypographyVariant.H5} marginBottom={'8px'}>
          {tWidget('ifIHadInvested.metal')}
        </Typography>
        <ButtonRadioGroup
          name={'metalIso'}
          value={metalValue}
          flexDirection={'row'}
          gap={'8px'}
          onChange={({ target: { value } }) => setMetalValue(value)}
        >
          <ButtonRadioSmallBordered value={EMetal.GOLD} label={tHeader('menu.gold')} />
          <ButtonRadioSmallBordered value={EMetal.SILVER} label={tHeader('menu.silver')} />
        </ButtonRadioGroup>
      </Box>
      <Box>
        <Typography variant={ETypographyVariant.H5} marginBottom={'28px'}>
          {tWidget('ifIHadInvested.startingInvestmentLabel')}
        </Typography>
        <Box maxWidth={'293px'}>
          <InputRangePrice
            defaultValue={INITIAL_INVESTMENT_DEFAULT as number}
            min={INITIAL_INVESTMENT_MIN as number}
            max={INITIAL_INVESTMENT_MAX as number}
            step={INITIAL_INVESTMENT_STEP as number}
            onChange={onChangeStartingInvestment}
            language={language}
            currency={currency}
            hasVisibleTooltip
          />
        </Box>
      </Box>
      <Box>
        <InputCheckbox
          value={hasMonthlyInvestment}
          label={
            <Typography variant={ETypographyVariant.BODY1} marginTop={'-2px'}>
              {tWidget('ifIHadInvested.monthlyInvestmentLabel')}
            </Typography>
          }
          onClick={() => setHasMonthlyInvestment(!hasMonthlyInvestment)}
        />
        {hasMonthlyInvestment && (
          <Box maxWidth={'293px'} marginTop={'28px'}>
            <InputRangePrice
              defaultValue={MONTHLY_INVESTMENT_DEFAULT as number}
              min={MONTHLY_INVESTMENT_MIN as number}
              max={MONTHLY_INVESTMENT_MAX as number}
              step={MONTHLY_INVESTMENT_STEP as number}
              onChange={onChangeMonthlyInvestment}
              language={language}
              currency={currency}
              hasVisibleTooltip
            />
          </Box>
        )}
      </Box>
      <Box>
        <Typography variant={ETypographyVariant.CAPTION1}>
          {tWidget('ifIHadInvested.todayYouWouldHave')}
        </Typography>

        <Box display={'flex'}>
          <Box cursor={'pointer'} onClick={() => setIsModalOpen(true)}>
            <Typography display={'flex'} variant={ETypographyVariant.H3} marginBottom={'8px'}>
              {formatCurrency(language, currency, props.portfolioTotal || 0)}
              <Box display={'flex'} alignItems={'flex-start'} marginLeft={'4px'}>
                <Icon type={EIcon.INFO} color={EColor.INFO} size={ESize.XXS} />
              </Box>
            </Typography>
          </Box>
        </Box>

        <Typography variant={ETypographyVariant.CAPTION1}>
          {tWidget('ifIHadInvested.totalProfit')}{' '}
          <Typography
            as={EActionAttribute.SPAN}
            variant={ETypographyVariant.BODY3}
            color={isPositiveProfit ? EColor.SUCCESS : EColor.DANGER}
          >
            {formatCurrency(language, currency, props.profitTotal || 0)}
            {` (${isPositiveProfit ? '+' : ''}${props.growth || 0}%)`}
          </Typography>
        </Typography>
        <ModalInvested5YearsAgo
          isOpen={isModalOpen}
          onCloseModal={() => setIsModalOpen(false)}
          {...props}
        />
      </Box>
    </>
  );
};
