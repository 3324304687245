import Trans from 'next-translate/Trans';

import { Box, Image, Paper, Typography } from '@core/component';
import { Translate } from '@core/constant';
import { useContextMediaQuery } from '@core/context';
import { EFilenameBlur, getStaticPathImage, getStaticPathImageBlurData } from '@core/media';
import { EActionAttribute, EColor, EFontWeight, ETypographyVariant } from '@core/type';
import { InvestmentChartsResponse } from '@core/type/api';

import { InvestmentLinkBlock } from './InvestmentLinkBlock';

export const ContentBlock = ({
  ...props
}: Partial<Pick<InvestmentChartsResponse, 'metalIso' | 'initialInvestment'>>) => {
  const { isMobile } = useContextMediaQuery();

  return (
    <Paper
      position={'relative'}
      boxShadow={EColor.ELEVATION_4}
      padding={isMobile ? '24px' : '40px'}
    >
      <Box display={'flex'} flexDirection={'column'} gap={isMobile ? '10px' : '30px'}>
        <Typography variant={ETypographyVariant.H1} fontWeight={EFontWeight.REGULAR}>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:ifIHadInvested.title`}
            components={{
              boldText: <Typography as={EActionAttribute.SPAN} variant={ETypographyVariant.H1} />,
              br: <br />,
            }}
          />
        </Typography>
        <Typography variant={ETypographyVariant.BODY1} maxWidth={'305px'}>
          <Trans
            i18nKey={`${Translate.common.WIDGET}:ifIHadInvested.description`}
            components={{
              br: <br />,
            }}
          />
        </Typography>
        {!isMobile && <InvestmentLinkBlock {...props} />}
      </Box>
      <Box position={'absolute'} top={isMobile ? '-25px' : '-20px'} right={isMobile ? '-4px' : '0'}>
        <Image
          src={getStaticPathImage('charts/content-block-metals.png')}
          blurDataURL={getStaticPathImageBlurData(EFilenameBlur['charts/content-block-metals.png'])}
          alt={'Metals'}
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          width={isMobile ? 92 : 117}
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          height={isMobile ? 120 : 151}
        />
      </Box>
    </Paper>
  );
};
